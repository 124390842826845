import ReactDOM from "react-dom/client";

// HACK(pi): The old iso page set the i18nextLng property to `en-US`
// thus, many people have that value set in their browser... however,
// the wallet-react library doesn't support that value, so it crashes on page load
// So, if this value is set, we clear it for now; we do it before almost any imports to make sure
// the code that fails on `en-US` doesn't trigger first.
if (localStorage.getItem("i18nextLng") == "en-US") {
  localStorage.setItem("i18nextLng", "en");
}

import "./i18n";
import "./styles/tailwind.scss";

const target = document.querySelector("#app");
const root = ReactDOM.createRoot(target);

import("./App").then(({ App }) => root.render(<App />));
